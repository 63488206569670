@import "src/shared/variable";

.cursor {
  width: 1rem;
  height: 1rem;
  background-color: $neon-color;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  transition: all 0.2s ease;
  transition-property: background, border, transform;
  transform-origin: 150% 150%;
  z-index: 999;
}

@media screen and (max-width: 600px) {
  .cursor {
    display: none;
  }
}
