@import "src/shared/variable";

.navbar {
  font-family: 'HeadingPro', sans-serif;
  color: $fourth-color;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;

  &__beneath {
    height: $nav-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 $padding-x;
  }

  &__title {
    width: 30px;
  }

  &__links {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;

    & > a > p {
      font-size: 15px;
      margin: 0 0 0 2.5vw;
      color: $fourth-color;
      font-family: 'HeadingPro', sans-serif;

      &:hover {
        color: $neon-color;
        text-decoration: none;
      }
    }

    & > div {
      margin-left: 2.5vw;
      border: none;
      background-color: transparent;
      border: 1.5px solid $fourth-color;
      color: $fourth-color;
      padding: 5px 15px;
      border-radius: 2.5rem;

      & > p {
        font-size: 15px;
        margin: 0;
      }

      &:hover {
        background-color: $neon-color;
        color: $background-color;
        border: 1.5px solid $neon-color;
      }
    }
  }
}


/* The progress container (grey background) */
.progress-container {
  width: 100%;
  height: 8px;
  background: transparent;
}

/* The progress bar (scroll indicator) */
.progress-bar {
  height: 8px;
  background: $neon-color;
  width: 100%;
  border-top-right-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
}


// media queries

@media screen and (max-width: 500px) {
  .navbar {
    &__title {
      //display: none;
    }

    &__beneath {
      justify-content: center;
      height: $small-nav-height;
    }

    &__links {
      & > a > p {
        font-size: 12px;
      }
      & > div {
        & > p {
          font-size: 12px;
        }
      }
    }

  }
}
