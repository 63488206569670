@import "src/shared/variable";

/* Setting fade transition and default settings */
body {
  background-color: $first-color;
  transition: background-color 1s ease;
}

.up-arrow {
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  position: fixed;
  bottom: 2.5%;
  right: 2.5%;
  color: $neon-color;
  font-family: 'HeadingPro', sans-serif;
}

/* panel styles */
.panel {
  min-height: 100vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 100%;
  padding-top: $nav-height;
  //outline: 10px solid hotpink;
  /* turn above on to see the edge of panels */
  &__big {
    width: 60%;
  }

  &__small {
    margin-top: 50px;
    width: 35%;
    display: block;
    padding-left: 5%;
    justify-content: center;
  }

  &__first {
    min-height: min-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    &__title {
      font-size: 90px;

      & > span {
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $neon-color;
      }
    }

    &__phone {
      width: 350px;
    }

    &__text {
      transform: translateY(-550%);
    }
  }

  &__code {
    font-size: 70px;
    margin-right: 40px;
  }

  &__arrow {
    font-size: 40px;
    color: $neon-color;
    animation-name: floating;
    -webkit-animation-name: floating;

    animation-duration: 1s;
    -webkit-animation-duration: 1s;

    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;

    &-container {
      justify-self: flex-end;
      margin-bottom: 25px;
    }
  }
}

.portfolio {
  text-decoration: line-through;
}

/* colours */
.color-violet {
  background-color: $background-color;
}

.color-indigo {
  background-color: $background-color;
}

.color-blue {
  background-color: $background-color;
}

.color-green {
  background-color: $background-color;
}

.color-yellow {
  background-color: $fifth-color;
}

.color-orange {
  background-color: $sixth-color;
}

.color-red {
  background-color: #F2293A;
}


#about {
  padding: 100px $padding-x 0 $padding-x;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  & > div > p {
    font-size: 40px;
  }
}


.about__text {
  max-width: 85%;
  word-wrap: break-word;
}

#projects {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  & > div > p {
    font-size: 40px;
  }
}

$duration: 30s;

.projects {
  &__newest {
    //padding: 0 -$padding-x !important;
    margin-top: $padding-x*0.8;
    background-color: $neon-color;
    width: 100%;
    font-family: 'HeadingPro', sans-serif;
    //overflow: hidden;
    //flex-wrap: nowrap;
    //box-sizing: content-box;

    & > div {
      //display: inline-block;
      white-space: nowrap;
      //box-sizing: border-box;
      //transform: translateX(-200px);
      //width: min-content;
      animation: move $duration linear infinite;
      color: $background-color;

      & > span {
        width: 100%;
        margin-right: 30px;
      }
    }
  }

  &__voodoo {
    width: 100%;

    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  &__container {
    width: 100%;

    &__half {
      width: 50%;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    margin-bottom: 20px;
  }
}


//animations

@keyframes move {
  to {
    transform: translateX(-100%);
  }
}


@keyframes floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(35%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0%);
  }
  50% {
    -webkit-transform: translateY(35%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}


.glitch {
  color: $neon-color;
  position: relative;
}

.glitch::after {
  content: attr(data-trick);
  position: absolute;
  top: 0;
  left: 0.5rem;
  color: $fourth-color;
  background-color: transparent;
  overflow: hidden;
  text-shadow: -0.5rem 0 $neon-color;
  clip: rect(90rem, 0, 0, 0);
  animation: noise 3s infinite linear alternate-reverse;
}

.glitch::before {
  content: attr(data-trick);
  top: 0;
  left: -1rem;
  color: transparent;
  background-color: transparent;
  overflow: hidden;
  text-shadow: 0.3rem 0 transparent;
  clip: rect(90rem, 0, 0, 0);
  animation: noise 3s infinite linear alternate-reverse;
}

@-webkit-keyframes noise {
  0% {
    clip: rect(2rem, 99.99rem, 12rem, 0);
  }
  4% {
    clip: rect(8rem, 99.99rem, 8rem, 0);
  }
  8% {
    clip: rect(2rem, 99.99rem, 1rem, 0);
  }
  12% {
    clip: rect(7rem, 99.99rem, 11rem, 0);
  }
  16% {
    clip: rect(3rem, 99.99rem, 8rem, 0);
  }
  20% {
    clip: rect(8rem, 99.99rem, 7rem, 0);
  }
  24% {
    clip: rect(6rem, 99.99rem, 9rem, 0);
  }
  28% {
    clip: rect(8rem, 99.99rem, 3rem, 0);
  }
  32% {
    clip: rect(11rem, 99.99rem, 3rem, 0);
  }
  36% {
    clip: rect(10rem, 99.99rem, 7rem, 0);
  }
  40% {
    clip: rect(10rem, 99.99rem, 3rem, 0);
  }
  44% {
    clip: rect(6rem, 99.99rem, 12rem, 0);
  }
  48% {
    clip: rect(6rem, 99.99rem, 4rem, 0);
  }
  52% {
    clip: rect(11rem, 99.99rem, 7rem, 0);
  }
  56% {
    clip: rect(6rem, 99.99rem, 4rem, 0);
  }
  60% {
    clip: rect(12rem, 99.99rem, 2rem, 0);
  }
  64% {
    clip: rect(10rem, 99.99rem, 2rem, 0);
  }
  68% {
    clip: rect(10rem, 99.99rem, 1rem, 0);
  }
  72% {
    clip: rect(2rem, 99.99rem, 11rem, 0);
  }
  76% {
    clip: rect(1rem, 99.99rem, 6rem, 0);
  }
  80% {
    clip: rect(12rem, 99.99rem, 8rem, 0);
  }
  84% {
    clip: rect(6rem, 99.99rem, 9rem, 0);
  }
  88% {
    clip: rect(3rem, 99.99rem, 6rem, 0);
  }
  92% {
    clip: rect(10rem, 99.99rem, 5rem, 0);
  }
  96% {
    clip: rect(1rem, 99.99rem, 3rem, 0);
  }
  100% {
    clip: rect(2rem, 99.99rem, 9rem, 0);
  }
}

@keyframes noise {
  0% {
    clip: rect(2rem, 99.99rem, 12rem, 0);
  }
  4% {
    clip: rect(8rem, 99.99rem, 8rem, 0);
  }
  8% {
    clip: rect(2rem, 99.99rem, 1rem, 0);
  }
  12% {
    clip: rect(7rem, 99.99rem, 11rem, 0);
  }
  16% {
    clip: rect(3rem, 99.99rem, 8rem, 0);
  }
  20% {
    clip: rect(8rem, 99.99rem, 7rem, 0);
  }
  24% {
    clip: rect(6rem, 99.99rem, 9rem, 0);
  }
  28% {
    clip: rect(8rem, 99.99rem, 3rem, 0);
  }
  32% {
    clip: rect(11rem, 99.99rem, 3rem, 0);
  }
  36% {
    clip: rect(10rem, 99.99rem, 7rem, 0);
  }
  40% {
    clip: rect(10rem, 99.99rem, 3rem, 0);
  }
  44% {
    clip: rect(6rem, 99.99rem, 12rem, 0);
  }
  48% {
    clip: rect(6rem, 99.99rem, 4rem, 0);
  }
  52% {
    clip: rect(11rem, 99.99rem, 7rem, 0);
  }
  56% {
    clip: rect(6rem, 99.99rem, 4rem, 0);
  }
  60% {
    clip: rect(12rem, 99.99rem, 2rem, 0);
  }
  64% {
    clip: rect(10rem, 99.99rem, 2rem, 0);
  }
  68% {
    clip: rect(10rem, 99.99rem, 1rem, 0);
  }
  72% {
    clip: rect(2rem, 99.99rem, 11rem, 0);
  }
  76% {
    clip: rect(1rem, 99.99rem, 6rem, 0);
  }
  80% {
    clip: rect(12rem, 99.99rem, 8rem, 0);
  }
  84% {
    clip: rect(6rem, 99.99rem, 9rem, 0);
  }
  88% {
    clip: rect(3rem, 99.99rem, 6rem, 0);
  }
  92% {
    clip: rect(10rem, 99.99rem, 5rem, 0);
  }
  96% {
    clip: rect(1rem, 99.99rem, 3rem, 0);
  }
  100% {
    clip: rect(2rem, 99.99rem, 9rem, 0);
  }
}


.custom-underline {
  box-shadow: inset 0 0px 0 white, inset 0 -11px 0 $neon-color;
}

// media queries


@media screen and (max-width: 1000px) {
  .projects {
    &__container__half {
      margin: 0;
    }
  }

  .panel {
    &__big {
      width: 100%;
    }

    &__small {
      width: 100%;
      padding: 0;
      justify-content: center;
    }

    &__first {
      flex-direction: column;
    }
  }

  .projects {
    &__voodoo__container {
      flex-direction: column-reverse;
    }

    &__newest {
      margin-bottom: 50px;
    }
  }

  .projects__container__half {
    width: 100%;
    margin: 30px 0;
  }

  .info {
    & > p {
      display: none;
    }
    & > a {
      margin-bottom: 100px;
    }
  }

  #voodooqr {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .panel__arrow-container {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 700px) {


  .panel__first {
    &__text {
      transform: translate(5px, -400%);
    }

    &__title {
      font-size: 60px;
    }

    &__phone {
      margin-top: 30px;
      &__container {
        height: 350px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  #about {
    & > div > p {
      font-size: 30px;
    }
  }

  h3 {
    font-size: 20px;
  }
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 23px;
  }
  .panel {
    &__first {

      &__title {
        font-size: 40px;
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .panel {
    &__first {
      &__title {
        font-size: 30px;
      }
    }
  }

  .up-arrow {
    font-size: 12px;
  }
}



@media screen and (max-width: 350px) {
  .panel__first {
    &__title {
      font-size: 30px;
    }
  }
}
