@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');

@font-face {
  font-family: 'RecoletaFont';
  src:  url('fonts/recoleta.woff2') format('woff2'),
  url('fonts/recoleta.woff') format('woff');
}

@font-face {
  font-family: 'HeadingPro';
  src:  url('fonts/heading-pro.woff2') format('woff2'),
  url('fonts/heading-pro.woff') format('woff');
}

$padding-x: 2.5%;
$nav-height: 80px;
$small-nav-height: 55px;
$main-text: 'Iner', sans-serif;

// colors

//$background-color: #0e1428*1.3;
$background-color: black;
$first-color: #222222;
$second-color: #2176ff;
$third-color: #2a9d8f;
$fourth-color: #faf3dd;
$fifth-color: #ff6b6b;
$sixth-color: #fca311;
$neon-color: #05f140;
//$fourth-color: #825959;

body {
  font-family: 'RecoletaFont', sans-serif;
  background: $background-color;
}

h1 {
  font-family: 'HeadingPro', sans-serif;
  letter-spacing: -3px;
}
