@import "src/shared/variable";

.green-footer {
  background-color: $fourth-color;
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'HeadingPro', sans-serif;
  color: $background-color;
  &__link-container {
    width: 18%;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > span {
    font-size: 12px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .green-footer {
    &__link-container {
      justify-content: space-evenly;
    }
  }
}
